import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Container, Row, Col, Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';

// Styled components
const StyledContainer = styled(Container)`
  padding-top: 5rem;
  padding-bottom: 5rem;
`;

const StyledAccordionHeader = styled(AccordionHeader)`
  background: linear-gradient(to right, #5e72e4, #825ee4);
  color: white;
`;

const FAQ = () => {
  const [openItem, setOpenItem] = useState('1');

  const toggle = (itemId) => {
    setOpenItem(openItem === itemId ? '' : itemId);
  };

  return (
    <>
      <StyledContainer>
        <Row>
          <Col md={12}>
            <h1 className="display-4 text-center">Frequently Asked Questions</h1>
            <p className="lead text-center">Find answers to common questions about our scooter rental services.</p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Accordion open={openItem} toggle={toggle}>
              <AccordionItem>
                <StyledAccordionHeader targetId="1" onClick={() => toggle('1')}>
                  What are your rental rates?
                </StyledAccordionHeader>
                <AccordionBody accordionId="1">
                  Our rental rates vary depending on the duration and type of scooter. We offer competitive pricing for daily, weekly, and monthly rentals.
                </AccordionBody>
              </AccordionItem>
              { /*
              <AccordionItem>
                <StyledAccordionHeader targetId="2" onClick={() => toggle('2')}>
                  What is your refund policy?
                </StyledAccordionHeader>
                <AccordionBody accordionId="2">
                  We offer a full refund for cancellations made at least 24 hours before the rental period starts. For cancellations made within 24 hours, a partial refund is provided.
                </AccordionBody>
              </AccordionItem>
               */ }
              <AccordionItem>
                <StyledAccordionHeader targetId="3" onClick={() => toggle('3')}>
                  Do you provide helmets with the scooters?
                </StyledAccordionHeader>
                <AccordionBody accordionId="3">
                  Yes, all our rentals come with a complimentary helmet to ensure your safety while riding.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <StyledAccordionHeader targetId="4" onClick={() => toggle('4')}>
                  What are the requirements to rent a scooter?
                </StyledAccordionHeader>
                <AccordionBody accordionId="4">
                  You need to be at least 20 years old with a valid driver's license to rent a scooter from us. Additionally, a security deposit is required at the time of rental.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <StyledAccordionHeader targetId="5" onClick={() => toggle('5')}>
                  Do you offer roadside assistance?
                </StyledAccordionHeader>
                <AccordionBody accordionId="5">
                  Yes, we provide 24/7 roadside assistance for all our scooters to ensure you have support whenever you need it.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <StyledAccordionHeader targetId="6" onClick={() => toggle('6')}>
                  Can I extend my rental period?
                </StyledAccordionHeader>
                <AccordionBody accordionId="6">
                  Yes, you can extend your rental period by contacting us before the end of your current rental period. Additional charges will apply based on the extended duration.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <StyledAccordionHeader targetId="7" onClick={() => toggle('7')}>
                  What should I do in case of an accident?
                </StyledAccordionHeader>
                <AccordionBody accordionId="7">
                  In case of an accident, ensure your safety first and seek medical attention if needed. Contact us immediately to report the accident and follow the instructions provided by our team.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <StyledAccordionHeader targetId="8" onClick={() => toggle('8')}>
                  Are there any mileage limits on rentals?
                </StyledAccordionHeader>
                <AccordionBody accordionId="8">
                  No, we do not have any mileage limits on our scooter rentals. You can ride as much as you need within the rental period.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <StyledAccordionHeader targetId="9" onClick={() => toggle('9')}>
                  How can I make a reservation?
                </StyledAccordionHeader>
                <AccordionBody accordionId="9">
                  You can make a reservation through our website or by contacting our customer service team. We recommend booking in advance to ensure availability.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <StyledAccordionHeader targetId="10" onClick={() => toggle('10')}>
                  Are there any discounts for long-term rentals?
                </StyledAccordionHeader>
                <AccordionBody accordionId="10">
                  Yes, we offer discounts for long-term rentals. Contact us for more details on our discounted rates for extended rental periods.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </Col>
        </Row>
      </StyledContainer>
    </>
  );
};

export default FAQ;
