import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';

// Styled components
const StyledContainer = styled(Container)`
  padding-top: 5rem;
  padding-bottom: 5rem;
`;

const StyledCard = styled(Card)`
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
`;

const StyledCardBody = styled(CardBody)`
  background: white;
  color: #333;
  padding: 2rem;
`;

const BookNow = () => {
  useEffect(() => {
    const loadScript = (id, src) => {
      if (!document.getElementById(id)) {
        const script = document.createElement('script');
        script.id = id;
        script.async = true;
        script.src = src;
        document.head.appendChild(script);
      }
    };

    // Load Booking Form Widget script
    loadScript(
      'loopit-pos-widget-booking-script',
      `https://signup.loopit.co/widget-loader-pos-plans.js?element=loopit-pos-widget-booking&source=8a12867e8bb2b716018bb31f960f52f5&to=${encodeURIComponent('https://yourwebsite.com/redirect')}`
    );

    // Load Point of Sale Widget script
    loadScript(
      'loopit-pos-widget-pos-script',
      'https://signup.loopit.co/widget-loader-pos-plans.js?element=loopit-pos-widget-pos&source=8a12867e8bb2b716018bb31f960f52f5&stock_no=12345'
    );
  }, []);

  return (
    <StyledContainer>
      <Row>
        <Col lg={12}>
          <h1 className="display-4 text-center">Book Now</h1>
          <p className="lead text-center">Fill out the form below to book your scooter now.</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <StyledCard>
            <StyledCardBody>
              <CardTitle tag="h5">Booking Form</CardTitle>
              <div id="loopit-pos-widget-booking" className="pos-widget"></div>
            </StyledCardBody>
          </StyledCard>
        </Col>
        <Col md={6}>
          <StyledCard>
            <StyledCardBody>
              <CardTitle tag="h5">Point of Sale</CardTitle>
              <div id="loopit-pos-widget-pos" className="pos-widget"></div>
            </StyledCardBody>
          </StyledCard>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default BookNow;
