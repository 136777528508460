import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';

// Styled components
const StyledAccordion = styled(Accordion)`
  margin-top: 2rem;
`;

const StyledAccordionHeader = styled(AccordionHeader)`
  background: linear-gradient(to right, #5e72e4, #825ee4);
  color: white;
`;

const StyledCard = styled(Card)`
  margin-top: 2rem;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
`;

const StyledCardBody = styled(CardBody)`
  background: white;
  color: #333;
`;

const StyledButton = styled(Button)`
  background-color: #007bff;
  border: none;
  &:hover {
    background-color: #0056b3;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ProfilePicture = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
`;

const ContactDetails = styled.div`
  margin-top: 20px;
`;

const About = () => {
  const [openItemId, setOpenItemId] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const toggle = (itemId) => {
    if (openItemId === itemId) {
      setOpenItemId(null);
    } else {
      setOpenItemId(itemId);
    }
  };

  const handleReadMore = (title, content, imageUrl, contact) => {
    setModalContent({ title, content, imageUrl, contact });
    setModal(true);
  };

  const toggleModal = () => setModal(!modal);

  return (
    <>
      <Container className="py-5">
        <Row>
          <Col md={12}>
            <h1 className="display-4 text-center">About VVK Rentals</h1>
          </Col>
        </Row>
        <StyledAccordion open={openItemId} toggle={toggle}>
          <AccordionItem>
            <StyledAccordionHeader targetId="1" onClick={() => toggle('1')}>
              Our Mission
            </StyledAccordionHeader>
            <AccordionBody accordionId="1">
              <strong>Our mission</strong> is to empower personal mobility with an eco-friendly, cost-effective, and convenient alternative. We aim to enhance the urban experience in Brisbane and the Gold Coast by providing easy access to reliable and affordable scooter rentals. We strive to promote sustainable urban mobility and reduce the carbon footprint through our services. Our commitment to excellent customer service ensures that every ride is smooth and enjoyable.
              <br /><br />
              <strong>Values:</strong>
              <ul>
                <li><strong>Customer Satisfaction:</strong> We prioritize the needs and satisfaction of our customers.</li>
                <li><strong>Innovation:</strong> We continuously seek to improve our services through innovation.</li>
                <li><strong>Sustainability:</strong> We are committed to promoting eco-friendly transportation solutions.</li>
                <li><strong>Community:</strong> We aim to support and contribute positively to our community.</li>
              </ul>
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <StyledAccordionHeader targetId="2" onClick={() => toggle('2')}>
              Our Vision
            </StyledAccordionHeader>
            <AccordionBody accordionId="2">
              Our vision is to become the leading scooter rental service in Australia. We aim to achieve this by consistently innovating and improving our services while promoting sustainable urban mobility. We envision a future where personal mobility is efficient, eco-friendly, and accessible to everyone. Our goal is to expand our services across Australia, providing a reliable alternative to traditional transportation methods.
              <br /><br />
              <strong>Future Goals:</strong>
              <ul>
                <li>Expand our fleet to include electric scooters and other eco-friendly vehicles.</li>
                <li>Introduce advanced booking and tracking systems for a seamless rental experience.</li>
                <li>Collaborate with local businesses to support sustainable development.</li>
                <li>Engage in community outreach programs to promote safe and responsible riding.</li>
              </ul>
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <StyledAccordionHeader targetId="3" onClick={() => toggle('3')}>
              Meet Our Team
            </StyledAccordionHeader>
            <AccordionBody accordionId="3">
              Our team is made up of passionate professionals who believe in creating a difference through service excellence and innovation. We are dedicated to providing our customers with the best scooter rental experience. Meet the individuals behind our success:
              <br /><br />
              <strong>Parth Patel:</strong> Founder & CEO - Parth has led VVK Rentals from a startup to a market leader in urban mobility solutions. His vision and leadership drive the company's growth and success.
              <br /><br />
              <strong>Palak Patel:</strong> Operations Manager - Palak oversees daily operations, ensuring top-notch service delivery across all locations. Her expertise ensures that our customers receive the best service possible.
             
            </AccordionBody>
          </AccordionItem>
        </StyledAccordion>
        <Row className="mt-4">
          <Col md={6}>
            <StyledCard>
              <StyledCardBody>
                <CardTitle tag="h5">Founder & CEO</CardTitle>
                <CardText>Parth Patel has led VVK Rentals from a startup to a market leader in urban mobility solutions.</CardText>
                <StyledButton color="primary" onClick={() => handleReadMore('Founder & CEO', 'Parth Patel has led VVK Rentals from a startup to a market leader in urban mobility solutions. With over 20 years of experience in the transportation industry, Parth has a proven track record of successful business ventures. His vision and leadership have been instrumental in the growth and success of VVK Rentals.', 'https://via.placeholder.com/150', 'Email: admin@vvkrentals.com.au ')}>Read More</StyledButton>
              </StyledCardBody>
            </StyledCard>
          </Col>
          <Col md={6}>
            <StyledCard>
              <StyledCardBody>
                <CardTitle tag="h5">Operations Manager</CardTitle>
                <CardText>Palak Patel oversees daily operations, ensuring top-notch service delivery across all locations.</CardText>
                <StyledButton color="primary" onClick={() => handleReadMore('Operations Manager', 'Palak Patel oversees daily operations, ensuring top-notch service delivery across all locations. With a background in logistics and customer service, Palak brings a wealth of knowledge and expertise to VVK Rentals. Her dedication to operational excellence ensures that our customers receive the best service possible.', 'https://via.placeholder.com/150', 'Email: admin@vvkrentals.com.au ')}>Read More</StyledButton>
              </StyledCardBody>
            </StyledCard>
          </Col>
          
        </Row>
      </Container>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{modalContent.title}</ModalHeader>
        <ModalBody>
          <ModalContent>
            <ProfilePicture src={modalContent.imageUrl} alt={modalContent.title} />
            <p>{modalContent.content}</p>
            <ContactDetails>{modalContent.contact}</ContactDetails>
          </ModalContent>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default About;
