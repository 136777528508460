import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FaMotorcycle, FaBookOpen, FaMapMarkerAlt, FaSmile, FaExchangeAlt, FaQuestionCircle, FaShieldAlt, FaHeadset } from 'react-icons/fa';

// Styled components
const StyledContainer = styled(Container)`
  padding-top: 5rem;
  padding-bottom: 5rem;
`;

const StepCard = styled(Card)`
  border: none;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  margin-top: 2rem;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-10px);
  }
`;

const StepIcon = styled.div`
  font-size: 4rem;
  color: #5e72e4;
  margin-bottom: 1rem;
  text-align: center;
`;

const StepTitle = styled(CardTitle)`
  font-size: 1.5rem;
  color: #5e72e4;
  text-align: center;
`;

const StepText = styled(CardText)`
  color: #333;
  text-align: center;
`;

const SectionTitle = styled.h2`
  color: #5e72e4;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

const HowItWorks = () => {
  return (
    <>
      <StyledContainer>
        <Row>
          <Col md={12}>
            <h1 className="display-4 text-center">How It Works</h1>
            <p className="lead text-center">Learn how our scooter rental process works, from booking to enjoying your ride.</p>
          </Col>
        </Row>
        <SectionTitle>Step-by-Step Process</SectionTitle>
        <Row>
          <Col md={6}>
            <StepCard>
              <CardBody>
                <StepIcon><FaMotorcycle /></StepIcon>
                <StepTitle>Step 1: Choose Your Scooter</StepTitle>
                <StepText>Browse through our selection of scooters and choose the one that best suits your needs. Each scooter comes with detailed specifications and rental rates.</StepText>
                <Button color="primary" tag={Link} to="/fleet">View Our Fleet</Button>
              </CardBody>
            </StepCard>
          </Col>
          <Col md={6}>
            <StepCard>
              <CardBody>
                <StepIcon><FaBookOpen /></StepIcon>
                <StepTitle>Step 2: Book Online</StepTitle>
                <StepText>Reserve your chosen scooter through our easy-to-use online booking system. You'll receive a confirmation email with all the details.</StepText>
                <Button color="primary" href="https://vvk.loopit.website/browse-cars" target="_blank">Book Now</Button>
              </CardBody>
            </StepCard>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <StepCard>
              <CardBody>
                <StepIcon><FaMapMarkerAlt /></StepIcon>
                <StepTitle>Step 3: Pick Up Your Scooter</StepTitle>
                <StepText>Visit our location to pick up your scooter. Our staff will provide a quick orientation to ensure you're comfortable with the scooter before you hit the road.</StepText>
              </CardBody>
            </StepCard>
          </Col>
          <Col md={6}>
            <StepCard>
              <CardBody>
                <StepIcon><FaSmile /></StepIcon>
                <StepTitle>Step 4: Enjoy Your Ride</StepTitle>
                <StepText>Enjoy your ride and explore the city at your own pace. If you have any issues, our customer service team is here to help.</StepText>
              </CardBody>
            </StepCard>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <StepCard>
              <CardBody>
                <StepIcon><FaExchangeAlt /></StepIcon>
                <StepTitle>Step 5: Return or Swap Anytime</StepTitle>
                <StepText>You can swap or return your scooter after the minimum rental period. Visit our website to book a return or swap appointment.</StepText>
                <Button color="primary" href="https://vvk.loopit.website/book" target="_blank">Book a Return</Button>
              </CardBody>
            </StepCard>
          </Col>
          <Col md={6}>
            <StepCard>
              <CardBody>
                <StepIcon><FaQuestionCircle /></StepIcon>
                <StepTitle>Frequently Asked Questions</StepTitle>
                <StepText>Have questions about our service? Check out our FAQ section for more information.</StepText>
                <Button color="primary" tag={Link} to="/faq">Go to FAQ</Button>
              </CardBody>
            </StepCard>
          </Col>
        </Row>
        <SectionTitle>Additional Information</SectionTitle>
        <Row>
          <Col md={6}>
            <StepCard>
              <CardBody>
                <StepIcon><FaShieldAlt /></StepIcon>
                <StepTitle>Safety and Insurance</StepTitle>
                <StepText>All our scooters are fully insured, and we provide safety gear including helmets. Make sure to follow all traffic rules and regulations for a safe ride.</StepText>
                <StepText>For more details on insurance and safety, visit our <Link to="/insurance-safety">Insurance and Safety</Link> page.</StepText>
              </CardBody>
            </StepCard>
          </Col>
          <Col md={6}>
            <StepCard>
              <CardBody>
                <StepIcon><FaHeadset /></StepIcon>
                <StepTitle>Customer Support</StepTitle>
                <StepText>Our customer support team is available 24/7 to assist you with any issues or queries. Contact us at <a href="mailto:admin@vvkrentals.com.au">admin@vvkrentals.com.au</a> or call us at 61 404 173 602.</StepText>
                <StepText>Visit our <Link to="/contact">Contact</Link> page for more information.</StepText>
              </CardBody>
            </StepCard>
          </Col>
        </Row>
      </StyledContainer>
     </>
  );
};

export default HowItWorks;
