import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, CardImg } from 'reactstrap';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

import axios from 'axios';


const API_URL = 'https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJoTL3G2FFkWsRLwNI0tzMNGU&fields=reviews&key=AIzaSyAe5ExBcKheXbju1FPrg1Dt-A1AAdamkf8'



const StyledContainer = styled(Container)`
  padding-top: 5rem;
  padding-bottom: 5rem;
`;

const StyledCard = styled(Card)`
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;

const StyledCardBody = styled(CardBody)`
  background: white;
  color: #333;
`;

const Title = styled.h1`
  color: #333;
  text-align: center;
  margin-bottom: 1rem;
`;

const Subtitle = styled.p`
  text-align: center;
  margin-bottom: 2rem;
  color: #666;
`;

const ReviewHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const ReviewAuthor = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const ReviewText = styled(CardText)`
  font-size: 1rem;
  color: #555;
`;

const StarRating = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`;

const Star = styled(FaStar)`
  color: #FFD700;
`;

const HalfStar = styled(FaStarHalfAlt)`
  color: #FFD700;
`;

const EmptyStar = styled(FaRegStar)`
  color: #FFD700;
`;

const ProfileImg = styled(CardImg)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

// Sample review data
const reviews = [
  {
    "author_name": "Patel Jainil",
    "author_url": "https://www.google.com/maps/contrib/115738241340177375650/reviews",
    "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjWJR3Tn7Org1SynU9aA5QjdqD-xYGbta-NH3IMrb3-ps8FO4Oad=s128-c0x00000000-cc-rp-mo",
    "rating": 5,
    "relative_time_description": "a year ago",
    "text": "Nice service and good support very nice people.overall good experience with them."
  },
  {
    "author_name": "Shree Umiya Switchgear -Rajan patel",
    "author_url": "https://www.google.com/maps/contrib/111581721311606432910/reviews",
    "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjWuDpBLDgf9tANI65WY9Ro_JNqp8vUzxIWqJ9TfGLpYTSI8ZBEP=s128-c0x00000000-cc-rp-mo-ba3",
    "rating": 5,
    "relative_time_description": "a year ago",
    "text": "Great support and service"
  },
  {
    "author_name": "Harsh Joshi",
    "author_url": "https://www.google.com/maps/contrib/105876187464208640272/reviews",
    "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjVUcpeP9cq-AyZx5TkA7wNb5BbPTGi7e8_G3YzNZ_HiMFp4m92o=s128-c0x00000000-cc-rp-mo",
    "rating": 5,
    "relative_time_description": "8 months ago",
    "text": "Good scooters, good service,"
  },
  {
    "author_name": "fenil pareghi",
    "author_url": "https://www.google.com/maps/contrib/109120084098641507142/reviews",
    "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocLnOScaCwW7kfPVwV0A1DCsi1XgCX4lgqwIRGNNddKKuwfVMg=s128-c0x00000000-cc-rp-mo",
    "rating": 5,
    "relative_time_description": "8 months ago",
    "text": "Best scooter service !!"
  },
  {
    "author_name": "Patel Fiyan",
    "author_url": "https://www.google.com/maps/contrib/100180934974963939399/reviews",
    "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjWnBbf8FN_vRYGxHbqEgFurfUMHw8IG_JYA8a2fhI4BXAsfFhI-kQ=s128-c0x00000000-cc-rp-mo",
    "rating": 5,
    "relative_time_description": "8 months ago",
    "text": "Best service"
  }
];

// Function to render stars
const renderStars = (rating) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(<Star key={i} />);
    } else if (i === Math.ceil(rating) && rating % 1 !== 0) {
      stars.push(<HalfStar key={i} />);
    } else {
      stars.push(<EmptyStar key={i} />);
    }
  }
  return stars;
};

const Reviews = () => {
    
  return (
    <>
      <StyledContainer>
        <Row>
          <Col md={12}>
            <Title>Customer Reviews</Title>
            <Subtitle>Read what our customers have to say about us.</Subtitle>
          </Col>
        </Row>
        <Row>
          {reviews.map((review, index) => (
            <Col md={4} key={index}>
              <StyledCard>
                <StyledCardBody>
                  <ReviewHeader>
                    <ProfileImg src={review.profile_photo_url} alt={review.author_name} />
                    <ReviewAuthor>
                      <a href={review.author_url} target="_blank" rel="noopener noreferrer">
                        {review.author_name}
                      </a>
                      <small className="text-muted">{review.relative_time_description}</small>
                    </ReviewAuthor>
                  </ReviewHeader>
                  <ReviewText>{review.text}</ReviewText>
                  <StarRating>{renderStars(review.rating)}</StarRating>
                </StyledCardBody>
              </StyledCard>
            </Col>
          ))}
        </Row>
      </StyledContainer>
    </>
  );
};

export default Reviews;
