import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import './../styles/Footer.css';

// Styled components
const StyledFooter = styled.footer`
  background: linear-gradient(90deg, #4c1d95 0%, #5e72e4 50%, #825ee4 100%);
  color: white;
  padding: 40px 0;
`;

const FooterHeading = styled.h5`
  color: #ffd700;
  border-bottom: 2px solid #ffd700;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

const FooterLink = styled(Link)`
  color: white;
  text-decoration: none;
  display: block;
  margin-bottom: 10px;

  &:hover {
    color: #ffd700;
  }
`;

const ExternalLink = styled.a`
  color: white;
  text-decoration: none;
  display: block;
  margin-bottom: 10px;

  &:hover {
    color: #ffd700;
  }
`;

const IconLink = styled.a`
  color: white;
  margin-right: 15px;
  font-size: 1.5rem;

  &:hover {
    color: #ff9f00;
  }
`;

const FooterSection = styled(Col)`
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const Copyright = styled.div`
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 10px;
  font-size: 0.9rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const PopupLink = styled.a`
  color: white;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: #ff9f00;
  }
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  color: #333;
  padding: 20px;
  border: 2px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.2rem;
    cursor: pointer;
  }

  h3 {
    margin-top: 0;
  }

  p {
    margin-bottom: 10px;
  }

  a {
    color: #5e72e4;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Footer = () => {
  const [popupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  };

  return (
    <StyledFooter>
      <Container>
        <Row>
          <FooterSection md={3} sm={6}>
            <FooterHeading>VVK Rentals</FooterHeading>
            <p>Office :24 Firelight Street, Sunnybank Hills, QLD 4109</p>
            <p>Home   : Unit 7, 2 Bos Drive, Coomera, QLD 4209</p>
            <p>Email: <ExternalLink href="mailto:admin@vvkrentals.com.au">admin@vvkrentals.com.au</ExternalLink></p>
            <p>Phone: <ExternalLink href="tel:+61404173602">+61 404 173 602</ExternalLink></p>
          </FooterSection>
          <FooterSection md={3} sm={6}>
            <FooterHeading>Quick Links</FooterHeading>
            <FooterLink to="/about">About Us</FooterLink>
            <FooterLink to="/services">Services</FooterLink>
            <FooterLink to="/contact">Contact</FooterLink>
          </FooterSection>
          <FooterSection md={3} sm={6}>
            <FooterHeading>Important Links</FooterHeading>
            <ExternalLink href="https://www.uber.com/au/en/deliver/?city=brisbane" target="_blank">UberEats Register</ExternalLink>
            <ExternalLink href="https://dasher.doordash.com/en-au" target="_blank">DoorDash Register</ExternalLink>
            <ExternalLink href="https://couriers.menulog.com.au/application" target="_blank">Menulog Register</ExternalLink>
          </FooterSection>
          <FooterSection md={3} sm={6}>
            <FooterHeading>Follow Us</FooterHeading>
            <div>
              <IconLink href="https://www.facebook.com/profile.php?id=61563138377512&mibextid=LQQJ4d" target="_blank">
                <i className="fab fa-facebook-f"></i>
              </IconLink>
              <IconLink href="https://www.instagram.com/vvk_rental/" target="_blank">
                <i className="fab fa-instagram"></i>
              </IconLink>
              <IconLink href="https://wa.me/61404173602" target="_blank">
                <i className="fab fa-whatsapp"></i>
              </IconLink>
            </div>
          </FooterSection>
        </Row>
        <Copyright>
          <p>&copy; {new Date().getFullYear()} VVK Rentals. All rights reserved. Designed and developed by <PopupLink onClick={togglePopup}>Mit Patel</PopupLink>.</p>
        </Copyright>
        {popupVisible && (
          <Popup>
            <div className="close" onClick={togglePopup}>&times;</div>
            <h3>Mit Patel</h3>
            <p>Phone: +61 404 600 793</p>
            <p>Email: <a href="mailto:mitpatelr1999@gmail.com">mitpatelr1999@gmail.com</a></p>
          </Popup>
        )}
      </Container>
    </StyledFooter>
  );
};

export default Footer;
