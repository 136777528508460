import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, Button, CardTitle, CardText } from 'reactstrap';

// Styled components for custom styling
const StyledCarousel = styled(Carousel)`
  max-height: 300px; /* Reduced height */
  overflow: hidden;
`;

const StyledCarouselItem = styled(CarouselItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 150px; /* Reduced image size */
    height: auto;
    margin: 0 10px;
    transition: transform 0.2s;
  }
  &.center-item img {
    transform: scale(1.1); /* Slightly larger for the center item */
  }
`;

const StyledButton = styled(Button)`
  background-color: #007bff;
  border-color: #007bff;
  &:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
`;

const SectionTitle = styled.h2`
  color: #007bff;
  margin-top: 30px;
  text-align: center;
`;

const FleetSection = styled.div`
  .carousel-inner {
    display: flex;
    align-items: center;
  }

  .carousel-item {
    transition: transform 0.5s ease-in-out;
  }

  .carousel-item-next,
  .carousel-item-prev,
  .carousel-item.active {
    display: block;
  }

  .carousel-item-next,
  .carousel-item-prev {
    transform: scale(0.8);
  }

  .carousel-item.active {
    transform: scale(1);
  }
`;

const Home = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [fleetData, setFleetData] = useState([]);

  useEffect(() => {
    // Mock fleet data (hard-coded)
    const mockFleetData = [
      {
        id: '1',
        name: 'Suzuki Address',
        description: 'A nimble and efficient scooter perfect for city commuting.',
        image: '/assets/images/sujuki_address.jpeg',
        engineInfo: '150cc, Automatic, Electric Start',
        vehicleInfo: 'A nimble and efficient scooter perfect for city commuting.'
      },
      {
        id: '2',
        name: 'Honda Deo',
        description: 'Reliable and comfortable for longer journeys.',
        image: '/assets/images/Honda_Deo.jpeg',
        engineInfo: '300cc, Automatic, Electric Start',
        vehicleInfo: 'Reliable and comfortable for longer journeys.'
      },
      {
        id: '3',
        name: 'Yamaha Delight',
        description: 'Sporty scooter with impressive performance.',
        image: '/assets/images/Yamaha_delight.jpeg',
        engineInfo: '200cc, Manual, Electric Start',
        vehicleInfo: 'Sporty scooter with impressive performance.'
      }
    ];
    setFleetData(mockFleetData);
  }, []);

  const toggleNextPrev = (index) => {
    if (!animating) setActiveIndex(index);
  };

  const fleetItems = fleetData.map((vehicle, index) => (
    <StyledCarouselItem key={index} onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} className={index === activeIndex ? 'center-item' : ''}>
      <img src={vehicle.image} alt={vehicle.name} />
      <div style={{ textAlign: 'center' }}>
        <CardTitle tag="h5">{vehicle.name}</CardTitle>
        <CardText>
          <strong>Vehicle Info:</strong> {vehicle.vehicleInfo}
          <br />
          <strong>Engine Info:</strong> {vehicle.engineInfo}
        </CardText>
        <StyledButton color="primary" href={`/fleet#${vehicle.name.toLowerCase().replace(/\s+/g, '-')}`}>Learn More</StyledButton>
      </div>
    </StyledCarouselItem>
  ));

  return (
    <>
      <SectionTitle>Meet Our Fleet</SectionTitle>
      <FleetSection>
        <StyledCarousel activeIndex={activeIndex} next={() => toggleNextPrev((activeIndex + 1) % fleetData.length)}
          previous={() => toggleNextPrev((activeIndex - 1 + fleetData.length) % fleetData.length)}>
          <CarouselIndicators items={fleetData} activeIndex={activeIndex} onClickHandler={setActiveIndex} />
          {fleetItems}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => toggleNextPrev((activeIndex - 1 + fleetData.length) % fleetData.length)} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={() => toggleNextPrev((activeIndex + 1) % fleetData.length)} />
        </StyledCarousel>
      </FleetSection>
    </>
  );
};

export default Home;
