import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Container, Row, Col, Card, CardImg, CardBody, CardTitle, CardText, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const Products = () => {
  const [modal, setModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const products = [
    {
      id: 1,
      name: 'City Scooter',
      description: 'Ideal for quick urban trips with easy handling and great fuel efficiency.',
      image: 'path/to/city-scooter.jpg',
      details: 'This scooter is perfect for navigating through city traffic, with a top speed of 50 km/h and a range of 100 km per charge.'
    },
    {
      id: 2,
      name: 'Touring Scooter',
      description: 'Best for longer journeys with enhanced comfort and larger fuel tank.',
      image: 'path/to/touring-scooter.jpg',
      details: 'Equipped with a comfortable seating arrangement, this scooter offers a smooth ride, ideal for longer distances up to 200 km per charge.'
    },
    {
      id: 3,
      name: 'Sport Scooter',
      description: 'High-performance scooter for the sporty at heart with sharp aesthetics.',
      image: 'path/to/sport-scooter.jpg',
      details: 'With a top speed of 80 km/h, this scooter is designed for those who love speed and agility in their ride.'
    }
  ];

  const toggleModal = (product = null) => {
    setSelectedProduct(product);
    setModal(!modal);
  };

  return (
    <>
      <Container className="py-5">
        <Row>
          <Col lg={12}>
            <h1 className="display-4 text-center">Our Products</h1>
            <p className="lead text-center">Explore our diverse range of scooters tailored to meet various needs and preferences.</p>
          </Col>
        </Row>
        <Row>
          {products.map((product) => (
            <Col md={4} key={product.id}>
              <Card>
                <CardImg top width="100%" src={product.image} alt={product.name} />
                <CardBody>
                  <CardTitle tag="h5">{product.name}</CardTitle>
                  <CardText>{product.description}</CardText>
                  <Button color="primary" onClick={() => toggleModal(product)}>View Details</Button>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      {selectedProduct && (
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>{selectedProduct.name}</ModalHeader>
          <ModalBody>
            <p>{selectedProduct.details}</p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>Close</Button>
          </ModalFooter>
        </Modal>
      )}
      {//<Footer />
      }
    </>
  );
};

export default Products;
