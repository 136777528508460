// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Products from './pages/Products';
import Fleet from './pages/Fleet'
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';
import AustralianLicence from './pages/AustralianLicence';
import HowItWorks from './pages/HowItWorks';
import Reviews from './pages/Reviews';
import InsuranceSafety from './pages/InsuranceSafety'
import BookNow from './pages/BookNow'
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import ScrollToTop from './components/ScrollToTop';
import LoopitWidgets from './components/LoopitWidgets'

function App() {
  return (
    <Router>
      <div className="app-container">
      <ScrollToTop />
        <Header />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/products" element={<Products />} />
            <Route path="/fleet" element={<Fleet />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/australian-licence" element={<AustralianLicence />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/insurance-safety" element={<InsuranceSafety />} />
            <Route path="/book-now" element={<BookNow />} />
            <Route path="/test" element={<LoopitWidgets />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
