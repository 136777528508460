import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';

// Styled components
const StyledContainer = styled(Container)`
  padding-top: 5rem;
  padding-bottom: 5rem;

  @media (max-width: 768px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`;

const StyledCard = styled(Card)`
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const CustomWidgetContainer = styled.div`
  background: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;

  h5 {
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 1.3rem;
    }

    @media (max-width: 480px) {
      font-size: 1.1rem;
    }
  }

  .choose-car-button {
    background-color: #5e72e4;
    border-color: #5e72e4;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    text-align: center;

    @media (max-width: 768px) {
      padding: 8px 16px;
    }

    @media (max-width: 480px) {
      padding: 6px 12px;
    }
  }

  .choose-car-button:hover {
    color: gold;
  }

  p {
    color: #333;

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }

    @media (max-width: 480px) {
      font-size: 0.8rem;
    }
  }
`;

const SectionTitle = styled.h2`
  color: #007bff;
  margin-top: 30px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const StyledCardBody = styled(CardBody)`
  background: white;
  color: #333;

  p {
    margin-top: 10px;
    font-size: 1rem;

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }

    @media (max-width: 480px) {
      font-size: 0.8rem;
    }
  }

  iframe {
    width: 100%;
    border: 0;

    @media (max-width: 768px) {
      height: 200px;
    }

    @media (max-width: 480px) {
      height: 150px;
    }
  }
`;

const Contact = () => {
  useEffect(() => {
    const loadScript = (id, src) => {
      if (!document.getElementById(id)) {
        const script = document.createElement('script');
        script.id = id;
        script.async = true;
        script.src = src;
        document.head.appendChild(script);
      }
    };

    // Load Loopit POS Leads Widget script
    loadScript(
      'loopit-pos-widget-script',
      'https://signup.loopit.co/widget-loader-pos-leads.js?element=loopit-pos-widget&source=8a12867e8bb2b716018bb31f960f52f5'
    );
  }, []);

  return (
    <StyledContainer>
      <Row>
        <Col lg={12}>
          <h1 className="display-4 text-center">Contact Us</h1>
          <p className="lead text-center">We'd love to hear from you. Please find the sample files below.</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <CustomWidgetContainer>
            {/* Loopit JS Widget */}
            <div id="loopit-pos-widget" className="pos-widget"></div>
            <script>
              var js = document.createElement("script");
              js.async = true;
              js.src = 'https://signup.loopit.co/widget-loader-pos-leads.js?element=loopit-pos-widget&source=8a12867e8bb2b716018bb31f960f52f5';
              document.getElementsByTagName("head")[0].appendChild(js);
            </script>
          </CustomWidgetContainer>
        </Col>
        
        <Col md={6}>
          <StyledCard>
            <StyledCardBody>
              <CardTitle tag="h5">Visit Us</CardTitle>
              <iframe
                title="location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11632.170134335637!2d153.3258191!3d-27.8673764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b9145ab247b93a3%3A0x78759e86dba18591!2s24%20Firelight%20St%2C%20Sunnybank%20Hills%20QLD%204109!5e0!3m2!1sen!2sus!4v1691561672485"
                height="250"
                allowFullScreen=""
                loading="lazy"
              ></iframe>
              <p className="mt-3"><strong>Office Address:</strong> 24 Firelight Street, Sunnybank Hills, QLD 4109</p>
              <p className="mt-3"><strong>Home Address:</strong> Unit 7, 2 Bos Drive, Coomera, QLD 4209</p>
              <p><strong>Email:</strong> <a href="mailto:admin@vvkrentals.com">admin@vvkrentals.com.au</a></p>
              <p><strong>Phone:</strong> <a href="tel:+61404173602">+61 404 173 602</a></p>
            </StyledCardBody>
          </StyledCard>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default Contact;
