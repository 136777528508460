import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Collapse, Button } from 'reactstrap';

// Styled components
const StyledContainer = styled(Container)`
  padding-top: 5rem;
  padding-bottom: 5rem;
`;

const StyledButton = styled(Button)`
  background-color: #5e72e4;
  border-color: #5e72e4;
  &:hover {
    background-color: #825ee4;
    border-color: #825ee4;
  }
`;

const StyledCard = styled(Card)`
  border: none;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  margin-top: 2rem;
`;

const StyledCardBody = styled(CardBody)`
  background: white;
  color: #333;
`;

const Services = () => {
  const [openCard, setOpenCard] = useState(null);

  const toggle = (cardId) => {
    setOpenCard(openCard === cardId ? null : cardId);
  };

  return (
    <>
   
      <StyledContainer>
        <Row>
          <Col md={12}>
            <h1 className="display-4 text-center">Our Services</h1>
            <p className="lead text-center">Explore our range of scooter rental services designed to fit every need, whether it's a quick ride around town or a long-term leasing option.</p>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <StyledCard>
              <StyledCardBody>
                <CardTitle tag="h5">Short-Term Rentals</CardTitle>
                <CardText>Perfect for international students looking for a flexible and convenient way to explore the city or commute.</CardText>
                <StyledButton onClick={() => toggle('shortTermRentals')} style={{ marginBottom: '1rem' }}>Learn More</StyledButton>
                <Collapse isOpen={openCard === 'shortTermRentals'}>
                  <CardText>
                    Our short-term rental service provides a flexible and affordable solution for those who need a scooter for a short period. Whether you're a tourist exploring the city or a local running errands, our scooters offer a convenient mode of transport.
                    <br /><br />
                    <strong>Rates:</strong> $120 for at least 2 months. Flexible pricing available for different cases.
                    <br /><br />
                    <strong>Available Models:</strong>
                    <ul>
                      <li>Suzuki Address</li>
                      <li>Honda Deo</li>
                      <li>Yamaha Delight</li>
                    </ul>
                    <br />
                    <strong>Additional Features:</strong> All rentals come with helmets and mobile phone holders for your convenience.
                    <br /><br />
                    <strong>Check Our Fleet:</strong> Visit our fleet page to explore many other scooter models available for rent.
                    <br /><br />
                    <strong>24/7 Roadside Assistance:</strong> We provide 24/7 roadside assistance for all our scooters and subscriptions.
                    <br /><br />
                    <strong>Rental Policies:</strong>
                    <ul>
                      <li>All riders must have a valid driver's license.</li>
                      <li>A security deposit is required at the time of rental.</li>
                      <li>Helmets are provided and must be worn at all times.</li>
                    </ul>
                    <br />
                    <strong>Usage:</strong> Ideal for students working in food delivery services like Menulog, DoorDash, and Uber Eats, or for daily commutes to jobs and universities.
                    <br /><br />
                    <strong>Australian Rules and Regulations:</strong> In Australia, it is mandatory to wear a helmet while riding a scooter. You must also follow all road rules, including not riding on footpaths and adhering to speed limits. Scooters are subject to random police checks to ensure compliance with safety regulations.
                  </CardText>
                </Collapse>
              </StyledCardBody>
            </StyledCard>
          </Col>
          <Col md={4}>
            <StyledCard>
              <StyledCardBody>
                <CardTitle tag="h5">Long-Term Subscriptions</CardTitle>
                <CardText>Ideal for residents who need a reliable and cost-effective transportation solution.</CardText>
                <StyledButton onClick={() => toggle('longTermSubscriptions')} style={{ marginBottom: '1rem' }}>Learn More</StyledButton>
                <Collapse isOpen={openCard === 'longTermSubscriptions'}>
                  <CardText>
                    Our long-term subscription service is perfect for those who need a scooter for an extended period. This option is ideal for students, professionals, and anyone looking for a cost-effective, long-term transportation solution.
                    <br /><br />
                    <strong>Subscription Benefits:</strong>
                    <ul>
                      <li>Monthly rates starting at $110 for at least 3 months. Flexible pricing available for different cases.</li>
                      <li>Access to our entire fleet with the ability to switch models monthly.</li>
                      <li>24/7 roadside assistance.</li>
                    </ul>
                    <br />
                    <strong>Customer Testimonials:</strong>
                    <blockquote>
                      "The long-term subscription is a lifesaver for my daily commute. I love the flexibility and the fact that I don't have to worry about maintenance." - Manav Patel.
                    </blockquote>
                    <br />
                    <strong>Australian Rules and Regulations:</strong> Subscribers must adhere to the same rules as daily renters, including wearing a helmet and following all road rules. Additionally, long-term renters must notify us of any changes to their driving license status.
                  </CardText>
                </Collapse>
              </StyledCardBody>
            </StyledCard>
          </Col>
          <Col md={4}>
            <StyledCard>
              <StyledCardBody>
                <CardTitle tag="h5">Corporate Solutions</CardTitle>
                <CardText>Customized plans for businesses to provide employees with an easy commute option.</CardText>
                <StyledButton onClick={() => toggle('corporateSolutions')} style={{ marginBottom: '1rem' }}>Learn More</StyledButton>
                <Collapse isOpen={openCard === 'corporateSolutions'}>
                  <CardText>
                    Our corporate solutions provide businesses with a practical and eco-friendly commuting option for their employees. These plans can be tailored to meet the specific needs of your company.
                    <br /><br />
                    <strong>Corporate Accounts:</strong>
                    <ul>
                      <li>Volume discounts for bulk rentals.</li>
                      <li>Customizable billing and reporting.</li>
                      <li>Priority support and maintenance services.</li>
                    </ul>
                    <br />
                    <strong>Example:</strong> A Food Outlet can utilize our scooter fleet for their customer delivery system, ensuring timely and eco-friendly deliveries.
                    <br /><br />
                    <strong>24/7 Roadside Assistance:</strong> We provide 24/7 roadside assistance for all our scooters and subscriptions.
                    <br /><br />
                    <strong>Australian Rules and Regulations:</strong> Businesses must ensure that all employees using the scooters comply with Australian road rules, including mandatory helmet use and adherence to speed limits. Corporate rentals may be subject to additional insurance requirements.
                  </CardText>
                </Collapse>
              </StyledCardBody>
            </StyledCard>
          </Col>
        </Row>
      </StyledContainer>
    
    </>
  );
};

export default Services;
