import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaMotorcycle, FaBars, FaTimes } from 'react-icons/fa';

// Styled Header Container
const HeaderContainer = styled.header`
  background: linear-gradient(90deg, #4c1d95 0%, #5e72e4 50%, #825ee4 100%);
  color: white;
  padding: 1rem 2rem;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  position: relative;
`;

// Navigation Styled
const Navigation = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

const LogoButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5e72e4;
  background-color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;

  &:hover {
    background-color: #ffd700;
    color: #4a63c4;
  }
`;

const LogoIcon = styled(FaMotorcycle)`
  margin-right: 10px;
  font-size: 3rem;
  color: #ffd700;
`;

const LogoImage = styled.img`
  height: 80px; /* Adjusted to make the logo larger */
  margin-right: 10px;

  @media (max-width: 768px) {
    height: 60px;
  }
`;

const NavList = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center; 
  flex-grow: 1;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  }
`;

const NavItem = styled.li`
  margin-left: 20px;

  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

const StyledNavLink = styled(NavLink)`
  color: white;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 5px;
  transition: color 0.3s, background-color 0.3s;
  font-size: 1.1rem; 
  white-space: nowrap; 

  &:hover {
    color: #ffd700;
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-left: auto;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    margin-left: 0;
  }
`;

const StyledButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5e72e4;
  background-color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #ffd700;
    color: #4a63c4;
  }
`;

const MobileMenuIcon = styled.div`
  display: none;
  font-size: 2rem;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

// React Component
const Header = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleLogoClick = () => {
    navigate('/');
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false); // Close the menu on link click
  };

  return (
    <HeaderContainer>
      <Navigation>
        <LogoContainer>
          <LogoButton onClick={handleLogoClick}>
            {
             // <LogoIcon />
            }
            <LogoImage src="/assets/images/logo.png" alt="VVK Rentals Logo" />
          </LogoButton>
        </LogoContainer>
        <MobileMenuIcon onClick={toggleMenu}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </MobileMenuIcon>
        <NavList isOpen={isOpen}>
          <NavItem>
            <StyledNavLink to="/about" onClick={handleLinkClick}>About Us</StyledNavLink>
          </NavItem>
          <NavItem>
            <StyledNavLink to="/services" onClick={handleLinkClick}>Services</StyledNavLink>
          </NavItem>
          <NavItem>
            <StyledNavLink to="/fleet" onClick={handleLinkClick}>Our Fleet</StyledNavLink>
          </NavItem>
          <NavItem>
            <StyledNavLink to="/contact" onClick={handleLinkClick}>Contact</StyledNavLink>
          </NavItem>
          <NavItem>
            <StyledNavLink to="/faq" onClick={handleLinkClick}>FAQ</StyledNavLink>
          </NavItem>
          <NavItem>
            <StyledNavLink to="/australian-licence" onClick={handleLinkClick} style={{ textAlign: 'center' }}>Australian Licence</StyledNavLink>
          </NavItem>
          <NavItem>
            <StyledNavLink to="/how-it-works" onClick={handleLinkClick}>How It Works</StyledNavLink>
          </NavItem>
          <NavItem>
            <StyledNavLink to="/reviews" onClick={handleLinkClick}>Reviews</StyledNavLink>
          </NavItem>
        </NavList>
        <ButtonGroup>
          <StyledButton href="https://vvk.loopit.website/login" target="_blank">Login</StyledButton>
          <StyledButton href="https://vvk.loopit.website/browse-cars" target="_blank">Book Now</StyledButton>
        </ButtonGroup>
      </Navigation>
    </HeaderContainer>
  );
};

export default Header;
