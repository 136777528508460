import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption, Button, Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

// Styled components for custom styling
const StyledCarousel = styled(Carousel)`
  max-height: 500px;
  overflow: hidden;
`;

const StyledCarouselItem = styled(CarouselItem)`
  img {
    width: 100%;
    object-fit: cover;
  }
`;

const StyledCard = styled(Card)`
  background: white;
  border: none;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  margin-top: 20px;
  transition: transform 0.2s;

  &.center-item {
    transform: scale(1.25);
  }
`;

const StyledButton = styled(Button)`
  background-color: #007bff;
  border-color: #007bff;
  &:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
`;

const SectionTitle = styled.h2`
  color: #007bff;
  margin-top: 30px;
  text-align: center;
`;

const InfoText = styled.p`
  color: #333;
  text-align: center;
`;

const TestimonialCard = styled(StyledCard)`
  text-align: center;
`;

const TestimonialImage = styled(CardImg)`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 0 auto 10px;
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.1);
  }
`;

const RequirementCard = styled(StyledCard)`
  text-align: left;
  margin-bottom: 20px;
`;

const RequirementIcon = styled.div`
  font-size: 2rem;
  color: #007bff;
  margin-right: 15px;
`;

const RequirementContent = styled.div`
  display: flex;
  align-items: center;
`;

const PartnerSection = styled.div`
  background: linear-gradient(to right, #4c1d95, #5e72e4, #825ee4);
  padding: 30px 0;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;

  .partner-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    gap: 20px;
    padding: 20px;

    .partner-item {
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      padding: 20px;
      border-radius: 8px;
      flex: 1 1 200px; /* Flex with a minimum width of 200px */
      margin: 10px;

      img {
        height: 40px;
        cursor: pointer;
        max-width: 100%;
      }

      @media (max-width: 768px) {
        padding: 10px;
        flex: 1 1 45%; /* Adjust to take nearly half of the container */
        
        img {
          height: 30px;
        }
      }

      @media (max-width: 480px) {
        padding: 5px;
        flex: 1 1 80px; /* Adjust to fit smaller screens */
        
        img {
          height: 25px;
        }
      }
    }
  }
`;

const FleetSection = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`;

const StyledCardImg = styled(CardImg)`
  height: 300px;
  object-fit: cover;
`;

const InclusionSection = styled(StyledCard)`
  padding: 40px 20px;
  text-align: left;
  margin-top: 30px;
`;

const InclusionTitle = styled.h2`
  color: #000;
  text-align: center;
  margin-bottom: 20px;
`;

const InclusionList = styled.ul`
  list-style: none;
  padding: 0;
  font-size: 1rem;
  line-height: 2rem;

  .inclusion-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .icon {
      font-size: 1.5rem;
      color: #007bff;
      margin-right: 10px;
    }

    .highlight {
      background-color: #007bff;
      color: #fff;
      padding: 2px 5px;
      border-radius: 5px;
    }
  }
`;

const Home = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [visiblePosts, setVisiblePosts] = useState(6); // Show 6 posts initially
  const [instagramPosts, setInstagramPosts] = useState([]);
  const [fleetData, setFleetData] = useState([]);

  useEffect(() => {
    // Mock Instagram posts (hard-coded)
    const mockInstagramPosts = [
      {
        id: '1',
        caption: 'Exploring the city with our reliable scooters!',
        media_url: '/assets/images/instagram3.jpg',
        permalink: '#'
      },
      {
        id: '2',
        caption: 'Enjoying the ride with the best scooters in town.',
        media_url: '/assets/images/instagram1.jpg',
        permalink: '#'
      },
      {
        id: '3',
        caption: 'Comfort and efficiency in every ride.',
        media_url: '/assets/images/instagram2.jpg',
        permalink: '#'
      }
    ];
    setInstagramPosts(mockInstagramPosts);

    const mockFleetData = [
      {
        id: '1',
        name: 'Suzuki Address',
        description: 'An efficient scooter perfect for city commuting.',
        image: '/assets/images/Suzuki_Address_110.jpg',
        engineInfo: '110cc, Automatic/Manual, Electric Start',
        vehicleInfo: 'An efficient scooter perfect for city commuting. Ideal for quick errands and navigating through traffic with ease.'
      },
      {
        id: '2',
        name: 'Honda Dio',
        description: 'Reliable and comfortable for longer journeys.',
        image: '/assets/images/Honda_Dio_110.jpg',
        engineInfo: '110cc, Automatic/Manual, Electric Start',
        vehicleInfo: 'Reliable and comfortable for longer journeys. Perfect for those who need a dependable ride for daily commutes or extended trips.'
      },
      {
        id: '3',
        name: 'Yamaha Delight',
        description: 'Sporty scooter with impressive performance.',
        image: '/assets/images/Yamaha_Delight_125.jpeg',
        engineInfo: '125cc, Automatic, Electric Start',
        vehicleInfo: 'Sporty scooter with impressive performance. Great for those who want a fun and dynamic riding experience with excellent handling.'
      },
      {
        id: '4',
        name: 'Kymco',
        description: 'Sporty scooter with impressive performance.',
        image: '/assets/images/Kimco_agility_125.jpg',
        engineInfo: '125cc, Automatic, Electric Start',
        vehicleInfo: 'Safe, reliable, Cheap to run and heaps of fun'
      }
    ];
  
    setFleetData(mockFleetData);
  }, []);

  const items = [
    { src: '/assets/images/image3.jpg', altText: 'Slide 1', caption: 'Explore the City with Ease' },
    { src: '/assets/images/image1.jpg', altText: 'Slide 2', caption: 'Efficient & Affordable Scooter Rentals' },
    { src: '/assets/images/image2.jpeg', altText: 'Slide 3', caption: 'Reliable Scooters for Every Need' }
  ];

  const toggleNextPrev = (index) => {
    if (!animating) setActiveIndex(index);
  };

  const slides = items.map((item, index) => (
    <StyledCarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={item.src}>
      <img src={item.src} alt={item.altText} />
      <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
    </StyledCarouselItem>
  ));

  const handleViewMore = () => {
    setVisiblePosts(prevCount => prevCount + 6); // Load 6 more posts
  };

  const testimonials = [
    {
      name: "Patel Jainil",
      profile_photo_url: "https://lh3.googleusercontent.com/a-/ALV-UjWJR3Tn7Org1SynU9aA5QjdqD-xYGbta-NH3IMrb3-ps8FO4Oad=s128-c0x00000000-cc-rp-mo",
      text: "Nice service and good support very nice people.overall good experience with them."
    },
    {
      name: "Harsh Joshi",
      profile_photo_url: "https://lh3.googleusercontent.com/a-/ALV-UjVUcpeP9cq-AyZx5TkA7wNb5BbPTGi7e8_G3YzNZ_HiMFp4m92o=s128-c0x00000000-cc-rp-mo",
      text: "Good scooters, good service,"
    },
    {
      name: "Patel Fiyan",
      profile_photo_url: "https://lh3.googleusercontent.com/a-/ALV-UjWnBbf8FN_vRYGxHbqEgFurfUMHw8IG_JYA8a2fhI4BXAsfFhI-kQ=s128-c0x00000000-cc-rp-mo",
      text: "Very affordable and easy to book. Will use again."
    }
  ];

  const safetyTips = [
    { text: "Always wear a helmet and protective gear to ensure your safety while riding.", icon: "fa fa-shield-alt" },
    { text: "Follow all traffic rules and regulations to avoid accidents and fines.", icon: "fa fa-traffic-light" },
    { text: "Keep a safe distance from other vehicles and pedestrians.", icon: "fa fa-road" },
    { text: "Avoid riding in bad weather conditions, such as heavy rain or strong winds.", icon: "fa fa-cloud-showers-heavy" }
  ];

  const safetyTipItems = safetyTips.map((tip, index) => (
    <CarouselItem key={index} onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)}>
      <CardText>
        <i className={tip.icon} style={{ fontSize: '2rem', color: '#007bff', marginRight: '15px' }}></i>
        {tip.text}
      </CardText>
    </CarouselItem>
  ));

  return (
    <>
      <StyledCarousel activeIndex={activeIndex} next={() => toggleNextPrev((activeIndex + 1) % items.length)}
        previous={() => toggleNextPrev((activeIndex - 1 + items.length) % items.length)}>
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={setActiveIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => toggleNextPrev((activeIndex - 1 + items.length) % items.length)} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={() => toggleNextPrev((activeIndex + 1) % items.length)} />
      </StyledCarousel>

      <PartnerSection>
        <div className="partner-content">
          <div className="partner-item">
            <a href="https://couriers.menulog.com.au/application" target="_blank" rel="noopener noreferrer">
              <img src="/assets/images/menulog_logo.svg" alt="Menulog" />
            </a>
          </div>
          <div className="partner-item">
            <a href="https://www.uber.com/au/en/deliver/?city=brisbane" target="_blank" rel="noopener noreferrer">
              <img src="/assets/images/ubereats_logo.svg" alt="Uber Eats" />
            </a>
          </div>
          <div className="partner-item">
            <a href="https://dasher.doordash.com/en-au" target="_blank" rel="noopener noreferrer">
              <img src="/assets/images/doordash_logo.svg" alt="DoorDash" />
            </a>
          </div>
        </div>
        <p>Approved to ride with all food delivery platforms</p>
      </PartnerSection>

      <Container className="my-5">
        <SectionTitle>How It Works</SectionTitle>
        <Row>
          <Col md={4}>
            <StyledCard>
              <CardBody>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <i className="fas fa-search" style={{ fontSize: '2rem', color: '#007bff', marginRight: '15px' }}></i>
                  <div>
                    <CardTitle tag="h5">Choose Your Scooter</CardTitle>
                    <CardText>Select from a variety of scooters based on your needs. Our fleet includes options for every type of rider, from casual city cruisers to high-performance models for long-distance travel.</CardText>
                    <StyledButton tag={Link} to="/how-it-works#choose-your-scooter">Learn More</StyledButton>
                  </div>
                </div>
              </CardBody>
            </StyledCard>
          </Col>
          <Col md={4}>
            <StyledCard>
              <CardBody>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <i className="fas fa-calendar-alt" style={{ fontSize: '2rem', color: '#007bff', marginRight: '15px' }}></i>
                  <div>
                    <CardTitle tag="h5">Book Online</CardTitle>
                    <CardText>Use our user-friendly online booking system to reserve your ride. Choose your dates, pick-up location, legal documentation, and any additional services you may need.</CardText>
                    <StyledButton tag={Link} to="/how-it-works#book-online">Learn More</StyledButton>
                  </div>
                </div>
              </CardBody>
            </StyledCard>
          </Col>
          <Col md={4}>
            <StyledCard>
              <CardBody>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <i className="fas fa-motorcycle" style={{ fontSize: '2rem', color: '#007bff', marginRight: '15px' }}></i>
                  <div>
                    <CardTitle tag="h5">Ride and Enjoy</CardTitle>
                    <CardText>Pick up your scooter from our convenient locations. Our staff will provide a brief orientation to ensure you are comfortable and confident before hitting the road.</CardText>
                    <StyledButton tag={Link} to="/how-it-works#ride-and-enjoy">Learn More</StyledButton>
                  </div>
                </div>
              </CardBody>
            </StyledCard>
          </Col>
        </Row>

        <SectionTitle>Rental Requirements</SectionTitle>
        <Row>
          <Col md={6}>
            <RequirementCard>
              <CardBody>
                <RequirementContent>
                  <RequirementIcon><i className="fas fa-id-card"></i></RequirementIcon>
                  <div>
                    <CardTitle tag="h5">Valid Driver's License</CardTitle>
                    <CardText>All riders must have a valid driver's license. This is essential to ensure that you are legally permitted to operate a scooter.</CardText>
                  </div>
                </RequirementContent>
              </CardBody>
            </RequirementCard>
          </Col>
          <Col md={6}>
            <RequirementCard>
              <CardBody>
                <RequirementContent>
                  <RequirementIcon><i className="fas fa-user-check"></i></RequirementIcon>
                  <div>
                    <CardTitle tag="h5">Minimum Age</CardTitle>
                    <CardText>Riders must be at least 20 years old. This age requirement helps ensure that riders have enough experience and maturity to handle the scooter safely.</CardText>
                  </div>
                </RequirementContent>
              </CardBody>
            </RequirementCard>
          </Col>
          <Col md={6}>
            <RequirementCard>
              <CardBody>
                <RequirementContent>
                  <RequirementIcon><i className="fas fa-id-badge"></i></RequirementIcon>
                  <div>
                    <CardTitle tag="h5">Proof of Identity</CardTitle>
                    <CardText>Riders need to provide proof of identity, such as a passport or national ID card. This helps us verify your identity and process your rental quickly.</CardText>
                  </div>
                </RequirementContent>
              </CardBody>
            </RequirementCard>
          </Col>
          <Col md={6}>
            <RequirementCard>
              <CardBody>
                <RequirementContent>
                  <RequirementIcon><i className="fas fa-money-check-alt"></i></RequirementIcon>
                  <div>
                    <CardTitle tag="h5">Security Deposit</CardTitle>
                    <CardText>A security deposit is required to cover any potential damages or issues during the rental period. The deposit is refundable upon safe return of the scooter.</CardText>
                  </div>
                </RequirementContent>
              </CardBody>
            </RequirementCard>
          </Col>
          <Col md={6}>
            <RequirementCard>
              <CardBody>
                <RequirementContent>
                  <RequirementIcon><i className="fas fa-globe"></i></RequirementIcon>
                  <div>
                    <CardTitle tag="h5">International Students/Visitors</CardTitle>
                    <CardText>International visitors must present an International Driving Permit (IDP) along with their home country license. This ensures compliance with local regulations.</CardText>
                  </div>
                </RequirementContent>
              </CardBody>
            </RequirementCard>
          </Col>
          <Col md={6}>
            <RequirementCard>
              <CardBody>
                <RequirementContent>
                  <RequirementIcon><i className="fas fa-shield-alt"></i></RequirementIcon>
                  <div>
                    <CardTitle tag="h5">Safety Compliance</CardTitle>
                    <CardText>Riders must agree to comply with all safety regulations, including wearing a helmet and following traffic rules. Safety is our top priority for all riders.</CardText>
                  </div>
                </RequirementContent>
              </CardBody>
            </RequirementCard>
          </Col>
        </Row>

        <SectionTitle>Meet Our Fleet</SectionTitle>
        <FleetSection>
          {fleetData.map((vehicle, index) => (
            <Col md={4} key={index} className="mb-4">
              <StyledCard>
                <StyledCardImg top src={vehicle.image} alt={vehicle.name} />
                <CardBody>
                  <CardTitle tag="h5">{vehicle.name}</CardTitle>
                  <CardText>
                  <strong>From $110</strong> per week
                    <br />
                    <strong>Vehicle Info:</strong> {vehicle.vehicleInfo}
                    <br />
                    <strong>Engine Info:</strong> {vehicle.engineInfo}
                  </CardText>
                  <StyledButton tag={Link} to={`/fleet#${vehicle.name.toLowerCase().replace(/\s+/g, '-')}`}>Learn More</StyledButton>
                </CardBody>
              </StyledCard>
            </Col>
          ))}
        </FleetSection>

        <SectionTitle>Insurance and Safety</SectionTitle>
        <Row>
          <Col lg={6}>
            <StyledCard>
              <CardBody>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <i className="fas fa-shield-alt" style={{ fontSize: '2rem', color: '#007bff', marginRight: '15px' }}></i>
                  <div>
                    <CardTitle tag="h5">Comprehensive Insurance</CardTitle>
                    <CardText>Our scooters come with comprehensive insurance coverage, including third-party liability. In case of any unforeseen incidents, you can ride with peace of mind knowing you are protected.</CardText>
                  </div>
                </div>
              </CardBody>
            </StyledCard>
          </Col>
          <Col lg={6}>
            <StyledCard>
              <CardBody>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <i className="fas fa-check-circle" style={{ fontSize: '2rem', color: '#007bff', marginRight: '15px' }}></i>
                  <div>
                    <CardTitle tag="h5">Safety First</CardTitle>
                    <CardText>We prioritize your safety by maintaining our fleet in top condition. Each scooter undergoes regular maintenance checks to ensure it is in perfect working order. We also provide helmets and other protective gear with every rental.</CardText>
                  </div>
                </div>
              </CardBody>
            </StyledCard>
          </Col>
        </Row>
        <StyledCard className="my-3">
          <CardBody>
            <CardTitle tag="h5">Safety Tips</CardTitle>
            <Carousel activeIndex={activeIndex} next={() => toggleNextPrev((activeIndex + 1) % safetyTips.length)}
              previous={() => toggleNextPrev((activeIndex - 1 + safetyTips.length) % safetyTips.length)}>
              <CarouselIndicators items={safetyTips} activeIndex={activeIndex} onClickHandler={setActiveIndex} />
              {safetyTipItems}
              <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => toggleNextPrev((activeIndex - 1 + safetyTips.length) % safetyTips.length)} />
              <CarouselControl direction="next" directionText="Next" onClickHandler={() => toggleNextPrev((activeIndex + 1) % safetyTips.length)} />
            </Carousel>
          </CardBody>
        </StyledCard>

        <SectionTitle>Vehicle Rental Inclusions</SectionTitle>
        <InclusionSection>
          <CardBody>
            <InclusionList>
             { //<li className="inclusion-item"><i className="fas fa-dollar-sign icon"></i> Starting from <span className="highlight">$110 per week</span> and includes:</li> 
             }
              <li className="inclusion-item"><i className="fas fa-hard-hat icon"></i> Helmet</li>
              <li className="inclusion-item"><i className="fas fa-shield-alt icon"></i> Comprehensive Insurance</li>
              <li className="inclusion-item"><i className="fas fa-box icon"></i> Food Delivery Bag</li>
              <li className="inclusion-item"><i className="fas fa-mobile-alt icon"></i> Quad-Lock Phone Mount</li>
             { // <li className="inclusion-item"><i className="fas fa-plug icon"></i> Quad-Lock Universal Mount Phone Adaptor (Yours to keep)</li>
             }
             <li className="inclusion-item"><i className="fas fa-infinity icon"></i> Unlimited KMs</li>
              <li className="inclusion-item"><i className="fas fa-tools icon"></i> Servicing</li>
              <li className="inclusion-item"><i className="fas fa-ambulance icon"></i> Roadside Assistance</li>
              <li className="inclusion-item"><i className="fas fa-file-alt icon"></i> Registration and Insurance Documents for Uber Eats, Menulog & DoorDash</li>
            </InclusionList>
          </CardBody>
        </InclusionSection>

        <SectionTitle>Testimonials</SectionTitle>
        <Row>
          {testimonials.map((testimonial, index) => (
            <Col md={4} key={index}>
              <TestimonialCard>
                <TestimonialImage top src={testimonial.profile_photo_url} alt={testimonial.name} />
                <CardBody>
                  <CardTitle tag="h5">{testimonial.name}</CardTitle>
                  <InfoText>{testimonial.text}</InfoText>
                </CardBody>
              </TestimonialCard>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Home;
