import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Button, UncontrolledCollapse } from 'reactstrap';
import { FaIdCard, FaMotorcycle, FaBookOpen, FaCheckCircle, FaGlobe, FaCertificate } from 'react-icons/fa';

// Styled components
const StyledContainer = styled(Container)`
  padding-top: 5rem;
  padding-bottom: 5rem;
`;

const StyledCard = styled(Card)`
  border: none;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  margin-top: 2rem;
`;

const StyledCardBody = styled(CardBody)`
  background: white;
  color: #333;
`;

const StyledButton = styled(Button)`
  background-color: #5e72e4;
  border-color: #5e72e4;
  &:hover {
    background-color: #825ee4;
    border-color: #825ee4;
  }
`;

const IconContainer = styled.div`
  font-size: 5rem; /* Increased icon size */
  color: #5e72e4;
  text-align: center;
  margin-bottom: 1rem;
`;

const SectionTitle = styled.h2`
  color: #5e72e4;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

const AustralianLicence = () => {
  return (
    <>
      <StyledContainer>
        <Row>
          <Col md={12}>
            <h1 className="display-4 text-center">Australian Driving Licence</h1>
            <p className="lead text-center">Understand the steps to acquire a driving licence in Australia, including special provisions for foreign nationals.</p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <StyledCard>
              <StyledCardBody>
                <IconContainer><FaIdCard /></IconContainer>
                <CardTitle tag="h5">Step 1: Obtain a CRN</CardTitle>
                <CardText>To start the process, obtain a Customer Reference Number (CRN) by visiting a TMR Customer Service Centre.</CardText>
                <StyledButton id="toggler1" style={{ marginBottom: '1rem' }}>Learn More</StyledButton>
                <UncontrolledCollapse toggler="#toggler1">
                  <Row>
                    <Col md={6}>
                      <CardText>
                        Steps to obtain a CRN:
                        <ul>
                          <li>Visit a TMR Customer Service Centre with identity documents.</li>
                          <li>Fill out the application form for a CRN.</li>
                          <li>Submit the form and documents to the service officer.</li>
                          <li>Receive your CRN after verification.</li>
                        </ul>
                        <strong>Requirements for foreign nationals:</strong>
                        <ul>
                          <li>Foreign nationals can drive with their overseas licence for up to three months.</li>
                          <li>After three months, they must apply for an Australian learner licence if they do not have a full licence from their country.</li>
                        </ul>
                        <a href="https://www.support.transport.qld.gov.au/qt/formsdat.nsf/forms/QF3503/$file/F3503_CFD.pdf" target="_blank" rel="noopener noreferrer">CRN Application Form</a>
                      </CardText>
                    </Col>
                    <Col md={6}>
                      <IconContainer><FaIdCard /></IconContainer>
                    </Col>
                  </Row>
                </UncontrolledCollapse>
              </StyledCardBody>
            </StyledCard>
            <StyledCard>
              <StyledCardBody>
                <IconContainer><FaMotorcycle /></IconContainer>
                <CardTitle tag="h5">Step 2: Complete Q-Ride Course</CardTitle>
                <CardText>Complete a Q-Ride course with an accredited provider to learn practical riding skills and road safety.</CardText>
                <StyledButton id="toggler2" style={{ marginBottom: '1rem' }}>Learn More</StyledButton>
                <UncontrolledCollapse toggler="#toggler2">
                  <Row>
                    <Col md={6}>
                      <CardText>
                        Steps to complete Q-Ride:
                        <ul>
                          <li>Choose an accredited Q-Ride provider.</li>
                          <li>Enroll in the course and complete the training sessions.</li>
                          <li>Pass the competency assessments.</li>
                          <li>Receive a Q-Ride competency certificate.</li>
                        </ul>
                        <a href="https://www.qld.gov.au/transport/licensing/driver-licensing/identity" target="_blank" rel="noopener noreferrer">Identity Requirements</a>
                      </CardText>
                    </Col>
                    <Col md={6}>
                      <IconContainer><FaMotorcycle /></IconContainer>
                    </Col>
                  </Row>
                </UncontrolledCollapse>
              </StyledCardBody>
            </StyledCard>
            <StyledCard>
              <StyledCardBody>
                <IconContainer><FaBookOpen /></IconContainer>
                <CardTitle tag="h5">Step 3: Pass the Knowledge Test</CardTitle>
                <CardText>After completing the Q-Ride course, pass the written knowledge test covering road rules and safe riding practices.</CardText>
                <StyledButton id="toggler3" style={{ marginBottom: '1rem' }}>Learn More</StyledButton>
                <UncontrolledCollapse toggler="#toggler3">
                  <Row>
                    <Col md={6}>
                      <CardText>
                        Steps to pass the knowledge test:
                        <ul>
                          <li>Study the Queensland Road Rules Handbook.</li>
                          <li>Take practice tests available online.</li>
                          <li>Visit a TMR Customer Service Centre to take the test.</li>
                          <li>Pass the test and receive a confirmation of your results.</li>
                        </ul>
                        <a href="https://www.service.transport.qld.gov.au/motorbikeknowledgeexam/public/Welcome.xhtml?dswid=3846" target="_blank" rel="noopener noreferrer">Motorbike Knowledge Exam</a>
                        <br />
                        <a href="https://www.service.transport.qld.gov.au/practiceroadrulestest/public/Welcome.xhtml?dswid=7125" target="_blank" rel="noopener noreferrer">Practice Road Rules Test</a>
                      </CardText>
                    </Col>
                    <Col md={6}>
                      <IconContainer><FaBookOpen /></IconContainer>
                    </Col>
                  </Row>
                </UncontrolledCollapse>
              </StyledCardBody>
            </StyledCard>
            <StyledCard>
              <StyledCardBody>
                <IconContainer><FaCheckCircle /></IconContainer>
                <CardTitle tag="h5">Step 4: Obtain Your QLD Rider's Licence</CardTitle>
                <CardText>Submit all the required documents and pass the practical riding test to obtain your Queensland rider's licence.</CardText>
                <StyledButton id="toggler4" style={{ marginBottom: '1rem' }}>Learn More</StyledButton>
                <UncontrolledCollapse toggler="#toggler4">
                  <Row>
                    <Col md={6}>
                      <CardText>
                        Steps to obtain your rider's licence:
                        <ul>
                          <li>Submit your CRN, Q-Ride certificate, and knowledge test results.</li>
                          <li>Book and pass the practical riding test.</li>
                          <li>Pay the applicable fees for the licence issuance.</li>
                          <li>Receive your Queensland rider's licence.</li>
                        </ul>
                      </CardText>
                    </Col>
                    <Col md={6}>
                      <IconContainer><FaCheckCircle /></IconContainer>
                    </Col>
                  </Row>
                </UncontrolledCollapse>
              </StyledCardBody>
            </StyledCard>
            <StyledCard>
              <StyledCardBody>
                <IconContainer><FaGlobe /></IconContainer>
                <CardTitle tag="h5">Exempt Countries</CardTitle>
                <CardText>Some countries are exempt from steps 2 and 3. Check the list of exempt countries through the official link.</CardText>
                <StyledButton href="https://www.qld.gov.au/transport/licensing/driver-licensing/overseas/transfer/exempt-countries#other-countries" target="_blank">View Exempt Countries</StyledButton>
              </StyledCardBody>
            </StyledCard>
          </Col>
        </Row>
      </StyledContainer>
    </>
  );
};

export default AustralianLicence;
