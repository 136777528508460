import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { FaShieldAlt, FaLifeRing, FaFirstAid, FaHandsHelping, FaLink } from 'react-icons/fa';

// Styled components
const StyledContainer = styled(Container)`
  padding-top: 5rem;
  padding-bottom: 5rem;
`;

const SectionTitle = styled.h2`
  color: #5e72e4;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

const StyledCard = styled(Card)`
  border: none;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  margin-top: 2rem;
`;

const StyledCardBody = styled(CardBody)`
  background: white;
  color: #333;
`;

const IconContainer = styled.div`
  font-size: 5rem; /* Increased icon size */
  color: #5e72e4;
  text-align: center;
  margin-bottom: 1rem;
`;

const InsuranceSafety = () => {
  return (
    <>
      <StyledContainer>
        <Row>
          <Col md={12}>
            <h1 className="display-4 text-center">Insurance and Safety</h1>
            <p className="lead text-center">Learn about our insurance policies and safety guidelines to ensure a secure and enjoyable ride.</p>
          </Col>
        </Row>
        <SectionTitle>Insurance Coverage</SectionTitle>
        <Row>
          <Col md={12}>
            <StyledCard>
              <StyledCardBody>
                <IconContainer><FaShieldAlt /></IconContainer>
                <CardTitle tag="h5">Comprehensive Insurance</CardTitle>
                <CardText>All our scooters come with comprehensive insurance coverage that includes fire, collision, and theft protection. </CardText>
                <CardText>Our insurance policy covers:</CardText>
                <ul>
                  <li>Third-party liability</li>
                  <li>Collision damage</li>
                  <li>Theft protection</li>
                  <li>Fire protection</li>
                </ul>
                <CardText>If you need more details about our insurance coverage, please <a href="mailto:admin@vvkrentals.com.au">contact our insurance team</a>.</CardText>
              </StyledCardBody>
            </StyledCard>
          </Col>
        </Row>
        <SectionTitle>Safety Guidelines</SectionTitle>
        <Row>
          <Col md={12}>
            <StyledCard>
              <StyledCardBody>
                <IconContainer><FaLifeRing /></IconContainer>
                <CardTitle tag="h5">Safety First</CardTitle>
                <CardText>We prioritize your safety and ensure all our scooters are well-maintained and equipped with the necessary safety gear. Please follow these guidelines to ensure a safe ride:</CardText>
                <ul>
                  <li>Always wear a helmet and other protective gear.</li>
                  <li>Follow traffic rules and regulations.</li>
                  <li>Do not ride under the influence of alcohol or drugs.</li>
                  <li>Be mindful of road conditions and weather.</li>
                  <li>Keep a safe distance from other vehicles.</li>
                </ul>
                <CardText>For more safety tips, visit our <a href="https://streetsmarts.initiatives.qld.gov.au/?gad_source=1&gbraid=0AAAAADxDl9W0Zh0WhFE2jquC9DOWcWd-0&gclid=Cj0KCQjwtsy1BhD7ARIsAHOi4xYmplA8jyWX6cF37IgnT9Lt84ERbTYa6PVR50qwSFudBqlfBUaj79kaAketEALw_wcB" target="_blank">Safety Tips</a> page.</CardText>
              </StyledCardBody>
            </StyledCard>
          </Col>
        </Row>
        <SectionTitle>Emergency Assistance</SectionTitle>
        <Row>
          <Col md={12}>
            <StyledCard>
              <StyledCardBody>
                <IconContainer><FaFirstAid /></IconContainer>
                <CardTitle tag="h5">24/7 Emergency Assistance</CardTitle>
                <CardText>Our 24/7 emergency assistance team is always ready to help you. In case of any breakdowns or accidents, contact us immediately.</CardText>
                <CardText>Emergency contact details:</CardText>
                <ul>
                  <li>Queensland Government: <a href="tel:000">000</a></li>
                 
                </ul>
                <CardText>Visit our <a href="https://www.qld.gov.au/emergency" target="_blank">Emergency Assistance</a> page for more information.</CardText>
              </StyledCardBody>
            </StyledCard>
          </Col>
        </Row>
        <SectionTitle>24/7 Breakdown Assistance</SectionTitle>
        <Row>
          <Col md={12}>
            <StyledCard>
              <StyledCardBody>
                <IconContainer><FaHandsHelping /></IconContainer>
                <CardTitle tag="h5">RACQ Fleet Care</CardTitle>
                <CardText>For any breakdowns, our RACQ Fleet Care team is available 24/7 to assist you.</CardText>
                <CardText>Contact RACQ Fleet Care:</CardText>
                <ul>
                  <li>Phone: <a href="tel:1800648058">1800 648 058</a></li>
                </ul>
                <CardText>Visit our <a href="https://www.racq.com.au/roadside-assistance/log-fleet-job" target="_blank">Breakdown Assistance</a> page for more information.</CardText>
              </StyledCardBody>
            </StyledCard>
          </Col>
        </Row>
        <SectionTitle>Additional Resources</SectionTitle>
        <Row>
          <Col md={12}>
            <StyledCard>
              <StyledCardBody>
                <IconContainer><FaLink /></IconContainer>
                <CardTitle tag="h5">Helpful Links</CardTitle>
                <CardText>Here are some additional resources to help you stay informed and safe:</CardText>
                <ul>
                  <li><a href="https://www.support.transport.qld.gov.au/qt/formsdat.nsf/forms/QF3503/$file/F3503_CFD.pdf" target="_blank">Queensland Transport Insurance Form</a></li>
                  <li><a href="https://www.qld.gov.au/transport/licensing/driver-licensing/identity" target="_blank">Driver Licensing Identity Requirements</a></li>
                  <li><a href="https://www.service.transport.qld.gov.au/motorbikeknowledgeexam/public/Welcome.xhtml?dswid=3846" target="_blank">Motorbike Knowledge Exam</a></li>
                  <li><a href="https://www.service.transport.qld.gov.au/practiceroadrulestest/public/Welcome.xhtml?dswid=7125" target="_blank">Practice Road Rules Test</a></li>
                </ul>
              </StyledCardBody>
            </StyledCard>
          </Col>
        </Row>
      </StyledContainer>
    </>
  );
};

export default InsuranceSafety;
