import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Card, CardBody, CardTitle, CardImg, Button, CardText } from 'reactstrap';

// Styled components
const StyledContainer = styled(Container)`
  padding-top: 5rem;
  padding-bottom: 5rem;
`;

const StyledCard = styled(Card)`
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
`;

const CustomWidgetContainer = styled.div`
  background: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  h5 {
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 1rem;
  }

  .choose-car-button {
    background-color: #5e72e4;
    border-color: #5e72e4;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .choose-car-button:hover {
    color: gold;
  }

  p {
    color: #333;
  }
`;

const StyledButton = styled(Button)`
  background-color: #007bff;
  border-color: #007bff;
  &:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
`;

const SectionTitle = styled.h2`
  color: #007bff;
  margin-top: 30px;
  text-align: center;
`;

const StyledCardImg = styled(CardImg)`
  height: 300px;
  object-fit: cover;
`;

const Fleet = () => {
  const [fleetData, setFleetData] = useState([]);

  useEffect(() => {
    // Mock fleet data (hard-coded)
    const mockFleetData = [
        {
            id: '1',
            name: 'Suzuki Address',
            description: 'An efficient scooter perfect for city commuting.',
            image: '/assets/images/Suzuki_Address_110.jpg',
            engineInfo: '110cc, Automatic/Manual, Electric Start',
            vehicleInfo: 'An efficient scooter perfect for city commuting. Ideal for quick errands and navigating through traffic with ease.'
          },
          {
            id: '2',
            name: 'Honda Dio',
            description: 'Reliable and comfortable for longer journeys.',
            image: '/assets/images/Honda_Dio_110.jpg',
            engineInfo: '110cc, Automatic/Manual, Electric Start',
            vehicleInfo: 'Reliable and comfortable for longer journeys. Perfect for those who need a dependable ride for daily commutes or extended trips.'
          },
          {
            id: '3',
            name: 'Yamaha Delight',
            description: 'Sporty scooter with impressive performance.',
            image: '/assets/images/Yamaha_Delight_125.jpeg',
            engineInfo: '125cc, Automatic, Electric Start',
            vehicleInfo: 'Sporty scooter with impressive performance. Great for those who want a fun and dynamic riding experience with excellent handling.'
          },
          {
            id: '4',
            name: 'Kymco',
            description: 'Sporty scooter with impressive performance.',
            image: '/assets/images/Kimco_agility_125.jpg',
            engineInfo: '125cc, Automatic, Electric Start',
            vehicleInfo: 'Safe, reliable, Cheap to run and heaps of fun'
          }
    ];
    setFleetData(mockFleetData);

    const loadScript = (id, src) => {
      if (!document.getElementById(id)) {
        const script = document.createElement('script');
        script.id = id;
        script.async = true;
        script.src = src;
        document.head.appendChild(script);
      }
    };

    /*
    // Load Loopit POS Widget script
    loadScript(
      'loopit-pos-widget-script',
      `https://signup.loopit.co/widget-loader-pos-plans.js?element=loopit-pos-widget&source=8a12867e8bb2b716018bb31f960f52f5&to=${encodeURIComponent('https://yourwebsite.com/redirect')}`
    );
    */
    // Add event listener for button clicks
    document.addEventListener('click', (event) => {
      if (event.target && event.target.matches('button.choose-car-button')) {
        const vehicleId = event.target.getAttribute('data-vehicle-id');
        handleButtonClick(vehicleId);
      }
    });
  }, []);

  const handleButtonClick = (vehicleId) => {
    console.log(`Fetch value for vehicle ID: ${vehicleId}`);
    // Example of making a backend request:
    // fetch(`https://yourbackend.com/api/vehicles/${vehicleId}`)
    //   .then(response => response.json())
    //   .then(data => {
    //     console.log(data);
    //   });
  };

  return (
    <StyledContainer>
      <Row>
        <Col lg={12}>
          <h1 className="display-4 text-center">Our Fleet</h1>
          <p className="lead text-center">Explore our diverse range of scooters tailored to meet various needs and preferences.</p>
        </Col>
      </Row>
      <Row>
        {fleetData.map((vehicle, index) => (
          <Col md={4} key={index} className="mb-4">
            <StyledCard>
              <StyledCardImg top src={vehicle.image} alt={vehicle.name} />
              <CardBody>
                <CardTitle tag="h5">{vehicle.name}</CardTitle>
                <CardText>
                  <strong>From $110</strong> per week
                  <br />
                  <strong>Vehicle Info:</strong> {vehicle.vehicleInfo}
                  <br />
                  <strong>Engine Info:</strong> {vehicle.engineInfo}
                </CardText>
               
                <StyledButton color="primary" href={`https://vvk.loopit.website/browse-cars`}>Book Now</StyledButton>
               
                </CardBody>
            </StyledCard>
          </Col>
        ))}
      </Row>
      {
        /*
       <Row>
        <Col md={12}>
          <StyledCard>
            <CustomWidgetContainer id="loopit-pos-widget" className="pos-widget"></CustomWidgetContainer>
          </StyledCard>
        </Col>
        </Row>
        */
      }
    </StyledContainer>
  );
};

export default Fleet;
